export default {
    app: {
        title: 'Admin Dashboard',
        home: 'Home',
        passwd: 'Change Password',
        logout: 'Logout'
    },
    menu: {
        dashboard: 'Dashboard',
        appoint: 'Appoint Records'
    },
    skin: {
        Blue: 'Blue',
        Green: 'Green'
    }
}
