<template>
    <el-dialog title="预览" :visible="visible" width="60%" @close="close">
        <div style="text-align: center;">
            <a v-if="form.MsgType == 'image'" :href="`https://wodecaichan.com/api/wechat/getMediaId?id=${form.MediaId}`"
                target="_blank">
                <img :src="`https://wodecaichan.com/api/wechat/getMediaId?id=${form.MediaId}`"
                    style="max-width:100%;max-height: 500px;" />
            </a>
            <video v-else-if="form == 'video'" :src="`https://wodecaichan.com/api/wechat/getMediaId?id=${form.MediaId}`"
                controls="controls" width="100%" height="400"></video>
            <a v-else-if="form.MsgType == 'link'" :href="form.MediaId" target="_blank">{{
                form.Content
            }}</a>
            <a v-else-if="form == 'voice'" :href="`https://wodecaichan.com/api/wechat/getMediaId?id=${form.MediaId}`"
                target="_blank">{{
                    form.Content
                }}</a>
            <span v-else>
                {{ form.Content }}
            </span>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">取 消</el-button>
            <el-button @click="formSubmit" type="primary" :loading="loading" :disabled="loading">下 载
            </el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    props: {
        visible: {
            default: false
        },
        form: {
            default: {}
        }
    },
    data() {
        return {
            loading: false
        }
    },
    methods: {
        formSubmit() {

        },
        close() {
            this.$emit('close')
        }
    }
}
</script>

<style scoped>
/deep/ .el-dialog__body {
    padding: 0px;
}

/deep/ .el-dialog__header {
    border-bottom: 1px solid #C0C4CC;
}

/deep/ .el-form {
    margin-bottom: 20px;
    border-bottom: 1px solid #C0C4CC;
}

.search {
    margin: 0 20px;
}

/deep/ .el-form-item {
    margin-bottom: 0px;
}
</style>
