<template>
    <el-dialog title="编辑" :visible="visible" width="40%" @close="close">
        <el-form ref="form" :model="form" label-width="140px">
            <el-form-item label="名称" prop="name" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
                <el-input v-model.trim="form.name" :disabled="!isNew"></el-input>
            </el-form-item>
            <el-form-item label="排序" prop="sort" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
                <el-input v-model.number="form.sort"></el-input>
            </el-form-item>
            <el-form-item label="每日数量限量" prop="quota" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
                <el-input v-model.number="form.quota"></el-input>
            </el-form-item>
            <el-form-item label="总数量" prop="total" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
                <el-input v-model.number="form.total"></el-input>
            </el-form-item>
            <el-form-item label="备注">
                <el-input type="textarea" v-model.trim="form.desc"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">{{$t('form.cancel')}}</el-button>
            <el-button @click="formSubmit" type="primary" :loading="loading" :disabled="loading">{{$t('form.ok')}}</el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    props: {
        visible: {
            default: false
        },
        form: {
        },
        isNew: {
            default: false
        }
    },
    data() {
        return {
            loading: false
        }
    },
    methods: {
        async formSubmit() {
            const valid = await this.$refs['form'].validate().then(res => res).catch(error => error)
            if (!valid) return
            const resp = await this.$http.post('/vaccine', this.form)
            if (resp.data.code != 200) {
                this.$message.error(resp.data.msg)
            } else {
                this.$message.success('保存成功')
                this.$emit('update')
                this.$emit('close')
            }
        },
        close() {
            this.$emit('close')
        }
    },
    mounted() {

    }
}
</script>

<style scoped>
/deep/ .el-dialog__body {
    padding: 0 20px;
}
</style>
