import Mock from 'mockjs'

const dastboard = {
    id: '10',
    label: 'menu.dashboard',
    path: '/main/dashboard',
    icon: 'el-icon-view'
}

const chatGPT = {
    id: '15',
    label: 'menu.chatGPT',
    path: '/main/chatGPT',
    icon: 'el-icon-chat-dot-round'
}

const message = {
    id: '20',
    label: 'menu.wechat_messages',
    path: '/main/wechat/messages',
    icon: 'el-icon-time'
}

const article = {
    id: '30',
    label: 'menu.article',
    path: '/main/article',
    icon: 'el-icon-odometer'
}

const tools = {
    id: '40',
    label: 'menu.tools',
    path: '/main/tools',
    icon: 'el-icon-magic-stick',
    children: [
        {
            id: '60-1',
            label: 'menu.tools_renminbi2daxie',
            path: '/main/tools/rmb2dx'
        },
    ]
}


const settings = {
    id: '60',
    label: 'menu.settings',
    path: '/main/settings',
    icon: 'el-icon-setting',
    children: [
        {
            id: '60-1',
            label: 'menu.settings_appoint_vaccine',
            path: '/main/settings/appoint/vaccine'
        },
        {
            id: '60-2',
            label: 'menu.settings_appoint_calendar',
            path: '/main/settings/appoint/calendar'
        },
        {
            id: '60-3',
            label: 'menu.settings_queue',
            path: '/main/settings/queue'
        }
    ]
}

const oplog = {
    id: '120',
    label: 'menu.oplog',
    path: '/main/oplog',
    icon: 'el-icon-notebook-1'
}

// const help = {
//     id: '200',
//     label: 'menu.help',
//     path: '/main/help',
//     icon: 'el-icon-help'
// }

let data = {
    getMenuItems() {
        let menu = []
        menu.push(dastboard)
        menu.push(chatGPT)
        if (localStorage.getItem('username') == 'admin') {
            menu.push(message)
            menu.push(article)
            menu.push(tools)
            menu.push(settings)
            menu.push(oplog)
        }
        return menu
    }
}


Mock.mock('/api/menu', () => {
    return { code: 200, data: data.getMenuItems() }
})