<template>
    <div>
        <el-page-header @back="$router.go(-1)" content="人民币转大写" />
        <div style="margin-top:20px">
            <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="输入金额">
                    <el-input-number v-model="form.fee" :precision="2" :step="1.0" :max="9999999999" class="input"
                        controls-position="right" />
                </el-form-item>
                <el-form-item label="转换结果">
                    <el-input v-model="form.result" class="input" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">转为人民币大写</el-button>
                    <el-button @click="onCopy">复制结果</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
export default {
    name: 'tools_renminbi2daxie',
    path: '/main/tools/rmb2dx',
    data() {
        return {
            form: {
                fee: 0,
                result: ''
            }
        }
    },
    methods: {
        // n为数字
        rmbUpper(n) {
            var fraction = ['角', '分']
            var digit = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖']
            var unit = [
                ['元', '万', '亿'],
                ['', '拾', '佰', '仟']
            ]
            var head = n < 0 ? '欠' : ''
            n = Math.abs(n)
            var s = ''
            for (let i = 0; i < fraction.length; i++) {
                s += (
                    digit[Math.floor(n * 10 * Math.pow(10, i)) % 10] + fraction[i]
                ).replace(/零./, '')
            }
            s = s || '整'
            n = Math.floor(n)
            for (let i = 0; i < unit[0].length && n > 0; i++) {
                var p = ''
                for (var j = 0; j < unit[1].length && n > 0; j++) {
                    p = digit[n % 10] + unit[1][j] + p
                    n = Math.floor(n / 10)
                }
                s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s
            }
            return (
                head + s
                    .replace(/(零.)*零元/, '元')
                    .replace(/(零.)+/g, '零')
                    .replace(/^整$/, '零元整')
            )
        },
        onCopy() {
            this.$copyText(this.form.result).then(
                e => {
                    this.$message.success('复制成功')
                },
                e => {
                    this.$message.error('复制失败:' + e)
                }
            )
        },
        onSubmit() {
            this.form.result = this.rmbUpper(this.form.fee)
        }
    }
}
</script>

<style scoped>
.input {
    width: 300px;
}

::v-deep .el-input-number .el-input__inner {
    text-align: left !important;
}
</style>