<template>
    <div>
        <el-page-header @back="$router.go(-1)" content="ChatGPT" />
        <div style="margin-top:20px">
            <el-form ref="form" :model="form" label-width="80px" size="small">
                <el-form-item label="引擎">
                    <el-select v-model="form.model" placeholder="请选择引擎">
                        <el-option label="text-davinci-003" value="text-davinci-003"></el-option>
                        <el-option label="text-davinci-002" value="text-davinci-002"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="问题">
                    <el-input type="textarea" :rows="6" v-model="form.content"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit" :loading="loading">提交</el-button>
                    <el-button type="default" @click="() => { this.response = '' }">清除</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="response" v-loading="loading">
            {{ response }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'chatGPT',
    path: '/main/chatGPT',
    data() {
        return {
            loading: false,
            form: { model: 'text-davinci-003', content: '' },
            response: ''
        }
    },
    methods: {
        async onSubmit() {
            this.loading = true
            try {
                const resp = await this.$http.post('/chatGPT', this.form)
                if (resp.data.code == 200) {
                    this.$message.success('成功')
                    this.response = resp.data.data
                } else {
                    this.$message.error('失败:' + resp.data.msg)
                }
            } catch (error) {
                this.$message.error('出错了:' + error.message)
            } finally {
                this.loading = false
            }
        },
        async getData() {
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style scoped>
.response {
    padding: 20px;
    white-space: pre-wrap;
}
</style>