<template>
    <v-chart class="chart" :option="option" />
</template>

<script>
    import { use } from "echarts/core";
    import { CanvasRenderer } from "echarts/renderers";
    import { PieChart } from "echarts/charts";
    import {
        TitleComponent,
        TooltipComponent,
        LegendComponent
    } from "echarts/components";
    import VChart, { THEME_KEY } from "vue-echarts";

    use([
        CanvasRenderer,
        PieChart,
        TitleComponent,
        TooltipComponent,
        LegendComponent
    ]);

    export default {
        name: "pieChart",
        components: {
            VChart
        },
        provide: {
            [THEME_KEY]: "white"
        },
        data() {
            return {
                option: {
                    title: {
                        text: "用户分布",
                        left: "center"
                    },
                    tooltip: {
                        trigger: "item",
                        formatter: "{a} <br/>{b} : {c} ({d}%)"
                    },
                    series: [
                        {
                            name: "用户分布",
                            type: "pie",
                            radius: "55%",
                            center: ["50%", "60%"],
                            data: [
                                { value: Math.floor(Math.random() * 1000), name: "大庆油田总医院" },
                                { value: Math.floor(Math.random() * 1000), name: "大庆市人民医院" },
                                { value: Math.floor(Math.random() * 1000), name: "大庆市龙南医院" },
                                { value: Math.floor(Math.random() * 1000), name: "大庆市第二医院" },
                            ],
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: "rgba(0, 0, 0, 0.5)"
                                }
                            }
                        }
                    ]
                }
            };
        }
    };
</script>

<style scoped>
    .chart {
        width: 100%;
        height: 400px;
    }
</style>
