<template>
    <div>
        <div v-for="(w,i) in times" :key="i">
            <div style="display: flex">
                <el-form-item prop="time">
                    <el-input v-model="times[i].time" style="width: 120px;margin-right:10px"
                              placeholder="时间"
                              size="small"></el-input>
                </el-form-item>
                <el-form-item prop="count">
                    <el-input-number v-model="times[i].count" style="width:100px;margin-right:10px"
                                     placeholder="号数"
                                     size="small" controls-position="right" :min="1"
                                     :max="100"></el-input-number>
                </el-form-item>
                <el-form-item>
                    <el-button @click="timesDel(i)" size="mini" icon="el-icon-close" round></el-button>
                </el-form-item>
            </div>
        </div>
        <el-button @click="timesAdd" size="small">添加</el-button>
        <el-button @click="importDlgShow" size="small">批量导入</el-button>
        <el-dialog title="批量导入" :visible.sync="dialogVisible" width="60%">
            <el-input type="textarea" :rows="20" placeholder="请输入内容" v-model="textImport"/>
            <span>请使用"时间,数量"的格式，一行一个；例如08:00-08:30,3</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="handleImport">确 定</el-button>
              </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: 'time-inputs',
        props: {
            times: {
                type: Array,
                default: function() {
                    return [{time:'', count:1}]
                }
            },
        },
        model: {
            prop: 'times',
            event: 'cc'
        },
        watch: {
            times: {
                // eslint-disable-next-line no-unused-vars
                handler(val, oldVal) {
                    this.$emit('cc', this.times)
                },
                deep: true
            }
        },
        data() {
            return {
                dialogVisible: false,
                textImport: '',
            }
        },
        methods: {
            timesAdd() {
                this.times.push({})
            },
            timesDel(i) {
                this.times.splice(i, 1);
            },
            importDlgShow(){
                this.textImport = ''
                this.times.forEach(e => {
                    this.textImport += `${e.time},${e.count}\n`
                })
                this.dialogVisible = true
            },
            handleImport() {
                this.times = []
                let arr = this.textImport.split("\n");
                for (let i = 0; i < arr.length; i++) {
                    let temp = arr[i].split(",");
                    this.times.push({time: temp[0], count: temp[1]})
                }
                this.dialogVisible = false
            },
        }
    }
</script>
