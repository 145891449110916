export default {
    app_title: '接种管理程序',
    app: {
        title: '我的工作台',
        home: '首页',
        passwd: '修改密码',
        logout: '退出'
    },
    menu: {
        dashboard: '系统首页',
        chatGPT: 'ChatGPT',
        wechat_messages: '微信消息',
        article: '文本',
        tools: '常用工具',
        tools_renminbi2daxie: '人民币转大写',
        settings: '系统设置',
        settings_appoint_vaccine: '疫苗设置',
        settings_appoint_calendar: '预约排期',
        settings_queue: '取号设置',
        oplog: '操作日志',
    },
    form: {
        search: '搜索',
        ok: '确认',
        cancel: '取消',
        export_xsl: '导出Excel'
    }
}
