import Vue from 'vue'
import App from './App.vue'
import store from './store'
import './plugins'

import {i18n, router} from './project'
// import {i18n, router} from '../../tuanjian'
// import {i18n, router} from '../../butsoon'
// import {i18n, router} from '../../htn-admin'
// import {i18n, router} from '../../hss'
// import {i18n, router} from '../../bsb'
// import {i18n, router} from '../../dip'

new Vue({
    render: h => h(App),
    router,
    store,
    i18n
}).$mount('#app')
