const data = {
    table: {
        columns: [
            {title: '类型', field: 'MsgType', width: '100'},
            {title: '内容', field: 'Content', width: ''},
            {title: '原始内容', field: 'MediaId', width: '180'},
            {title: '时间', field: 'createdAt', width: '200'}
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 20
    }
}

export default data
