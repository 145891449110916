import Mock from 'mockjs'
import './data/user'
import './data/menu'
// import './data/appoint'

Mock.setup({
    timeout: '100-300'
})

Mock.mock('/api/version', () => {
    return {
        'url': 'https://mingde.mriabc.com/download/',
        'version': '1.2.4'
    }
})
