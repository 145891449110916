<template>
    <zk-admin-table title="微信消息" :loading="loading" :table="table" @change="handleTableChange"
        @select="handleTableSelect">
        <template v-slot:buttons>
            <el-row style="float: right">
                <el-button size="mini" icon="el-icon-search" circle @click="searchVisible = !searchVisible"></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
            </el-row>
        </template>
        <template v-slot:search>
            <search-form @search="handleSearch" v-show="searchVisible" />
        </template>
        <el-table-column prop="MsgType" label="类型" width="80" />
        <el-table-column prop="Content" label="内容" width="">
            <template slot-scope="scope">
                <div v-if="scope.row.MsgType == 'image'" @click="handlePreview(scope.row)" style="cursor:pointer">
                    <img :src="`https://wodecaichan.com/api/wechat/getMediaId?id=${scope.row.MediaId}`"
                        style="max-width:300px;max-height: 80px;" />
                </div>
                <!-- <a v-if="scope.row.MsgType == 'image'" @click="handlePreview" :href="`#https://wodecaichan.com/api/wechat/getMediaId?id=${scope.row.MediaId}`" target="_blank">
                    <img :src="`https://wodecaichan.com/api/wechat/getMediaId?id=${scope.row.MediaId}`"
                        style="max-width:300px;max-height: 80px;" />
                </a> -->
                <video v-else-if="scope.row.MsgType == 'video'" :src="`https://wodecaichan.com/api/wechat/getMediaId?id=${scope.row.MediaId}`" controls="controls" width="100%" height="120"></video>
                <a v-else-if="scope.row.MsgType == 'link'" :href="scope.row.MediaId" target="_blank">{{
                    scope.row.Content
                }}</a>
                <a v-else-if="scope.row.MsgType == 'voice'"
                    :href="`https://wodecaichan.com/api/wechat/getMediaId?id=${scope.row.MediaId}`" target="_blank">{{
                        scope.row.Content
                    }}</a>
                <span v-else>
                    {{ scope.row.Content }}
                </span>
            </template>
        </el-table-column>
        <el-table-column prop="createdAt" label="时间" width="230" />
        <!-- <el-table-column align="center"
                         v-for="(v, idx) in table.columns"
                         :prop="v.field"
                         :label="v.title"
                         :width="v.width"
                         :formatter="v.formatter"
                         :key="idx">
        </el-table-column> -->
        <!-- <el-table-column align="center" width="120" label="操作" fixed="right">
            <template slot-scope="scope">
                <el-button type="warning" size="mini" @click="handleUpdate(scope.row)" icon="el-icon-edit"></el-button>
            </template>
        </el-table-column> -->
        <template v-slot:dialog>
            <data-form :visible="dialogVisible" :form="form" @close="dialogClose" @update="getData"
                :isNew="isNew"></data-form>
            <data-preview :visible="previewVisible" :form="form" :total="table.total"
                @close="previewVisible = false" />
        </template>
    </zk-admin-table>
</template>

<script>
import SearchForm from './widgets/searchForm'
import DataForm from './widgets/dataForm'
import DataPreview from './widgets/dataPreview'
import data from './data'

export default {
    name: 'main-wechat-messages',
    path: '/main/wechat/messages',
    components: {
        SearchForm,
        DataForm,
        DataPreview
    },
    data() {
        return {
            loading: false,
            table: data.table,
            dialogVisible: false,
            searchVisible: true,
            previewVisible: false,
            search: {},
            form: {},
            isNew: false,
            multipleSelection: []
        }
    },
    methods: {
        dialogClose() {
            this.dialogVisible = false
            this.getData()
        },
        handlePreview(row) {
            this.form = row
            console.log(row)
            this.previewVisible = true
        },
        handleSearch(e) {
            this.search = e
            this.table.page = 1
            this.getData()
        },
        handleTableChange(e) {
            this.table = e
            this.getData()
        },
        handleTableSelect(val) {
            this.multipleSelection = val
        },
        async getData() {
            this.loading = true
            try {
                const resp = await this.$http.get(`/wechat/messages?page=${this.table.page}&pageSize=${this.table.pageSize}`, { params: this.search })
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.table.data = resp.data.data
                    this.table.total = resp.data.total
                }
            } catch (e) {
                this.$message.error(e.message)
            }
            this.loading = false
        }
    },
    mounted() {
        this.getData()
    }
}
</script>
